<header class="navbar navbar-expand-lg navbar-dark bg-dark d-flex justify-content-between">
  <div class="col-6">
    <a class="navbar-brand" href="#"><img src="/assets/Logo-weiss@2x.png"></a>
  </div>
  <ul class="col-6 navbar-nav mr-auto text-right d-flex justify-content-end">
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" [routerLink]="['/icons']">Icons</a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" [routerLink]="['/barchart']">Barchart</a>
    </li>
  </ul>
</header>

    <!-- Begin page content -->

<main role="main" class="container">
  <router-outlet></router-outlet>
</main>


