import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {CustomDatePipe} from './_helper/custom.datepipe';

import { VisualisationComponent } from './visualisation/visualisation.component';
import { VisualisationIconsComponent } from './visualisation/visualisation-icons.component';
import { ChartsModule } from 'ng2-charts';
import { IconsComponent } from './icons/icons.component';
import { BarchartComponent } from './barchart/barchart.component';

@NgModule({
  declarations: [
    AppComponent,
    VisualisationComponent,
    VisualisationIconsComponent,
    IconsComponent,
    CustomDatePipe,
    BarchartComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ChartsModule
  ],
  // providers: [{ provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true }],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

