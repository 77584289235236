import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IconsComponent } from './icons/icons.component';
import { BarchartComponent } from './barchart/barchart.component';


const routes: Routes = [
  // Default
  { path: '', component: IconsComponent, pathMatch: 'full' },
  { path: 'icons', component: IconsComponent, pathMatch: 'full' },
  { path: 'barchart', component: BarchartComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
