<h1>Simulation Stromerzeugung</h1>

<div style="display: block; ">
  <canvas baseChart
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [plugins]="barChartPlugins"
          [legend]="barChartLegend"
          [chartType]="barChartType">
  </canvas>
</div>
