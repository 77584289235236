<h1 class="mt-4"><span class="text-muted">Aktuelle Zeit</span> {{ currentTime | date:'d. MMMM y, HH:mm' }}</h1>
<div class="row">
  <div class="col-6 d-flex justify-content-between flex-wrap align-items-center">
    <ng-container *ngFor="let tile of renewables; index as i; first as isFirst">
      <div *ngIf="isFirst" class="tile first col-12" [ngStyle]="{ 'background-color': tile.color ? tile.color : '#456456' }">
        <img src="/assets/{{ tile.icon }}" alt="{{ tile.title }}">
        <div class="value mb-2 lead">
          Aktuell: {{ tile.value }} MWh
        </div>
      </div>
      <div *ngIf="!isFirst" class="tile col-6" [ngStyle]="{ 'background-color': tile.color ? tile.color : '#456456' }">
        <img src="/assets/{{ tile.icon }}" alt="{{ tile.title }}">
        <div class="value mb-2 lead">{{ tile.value }} MWh</div>
      </div>
    </ng-container>
  </div>
  <div class="col-6 d-flex justify-content-between flex-wrap align-items-center">
    <ng-container *ngFor="let tile of conventional; index as i; first as isFirst">
      <div *ngIf="isFirst" class="tile first col-12" [ngStyle]="{ 'background-color': tile.color ? tile.color : '#456456' }">
        <img src="/assets/{{ tile.icon }}" alt="{{ tile.title }}">
        <div class="value mb-2 lead">{{ tile.value }} MWh</div>
      </div>
      <div *ngIf="!isFirst" class="tile col-6" [ngStyle]="{ 'background-color': tile.color ? tile.color : '#456456' }">
        <img src="/assets/{{ tile.icon }}" alt="{{ tile.title }}">
        <div class="value mb-2 lead">{{ tile.value }} MWh</div>
      </div>
    </ng-container>
  </div>
</div>

<div class="row my-4">
  <div class="col-md-6">
    <h2>Erneuerbare Energien</h2>
    <div class="dataset" *ngFor="let tile of renewables; index as i; first as isFirst">
      {{ tile.title }}
      <div class="progress">
        <div *ngIf="tile.min" class="progress-bar progress-bar-striped" role="progressbar" [ngStyle]="{ 'width': tile.perc + '%', 'background-color': tile.color ? tile.color : '#456456' }" ></div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <h2>Konventionelle Energien</h2>
    <div class="dataset" *ngFor="let tile of conventional; index as i; first as isFirst">
      {{ tile.title }}
      <div class="progress">
        <div *ngIf="tile.min" class="progress-bar progress-bar-striped" role="progressbar"  [ngStyle]="{ 'width': tile.perc + '%', 'background-color': tile.color ? tile.color : '#456456' }"></div>
      </div>
    </div>
  </div>
</div>
