import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visualisation-icons',
  templateUrl: './visualisation-icons.component.html',
  styleUrls: ['./visualisation-icons.component.scss']
})
export class VisualisationIconsComponent implements OnInit {

  icons:any[] = [
    { "key":  "total_renewables",  "title": "Stromerzeugung-Regenerativ-Gesamt"   },
    { "key": "total_conventional", "title": "Stromerzeugung-Konventionell-Gesamt" },
    { 'key': "total_all", "title": "Stromerzeugung-Gesamt" }
    /*
    { "Stromerzeugung-Regenerativ-Biomasse", "https://oklabpdm.uber.space/powergeneration/rest/biomass.json", "biomass", 5 },
    { "Stromerzeugung-Regenerativ-Wasserkraft", "https://oklabpdm.uber.space/powergeneration/rest/hydropower.json", "hydropower", 5 },
    { "Stromerzeugung-Regenerativ-Wind", "https://oklabpdm.uber.space/powergeneration/rest/wind.json", "wind", 5 },
    { "Stromerzeugung-Regenerativ-Wind-Offshore", "https://oklabpdm.uber.space/powergeneration/rest/wind_offshore.json", "wind_offshore", 5 },
    { "Stromerzeugung-Regenerativ-Wind-Onshore", "https://oklabpdm.uber.space/powergeneration/rest/wind_onshore.json", "wind_onshore", 5 },
    { "Stromerzeugung-Regenerativ-Photovoltaik", "https://oklabpdm.uber.space/powergeneration/rest/photovoltaics.json", "photovoltaics", 5 },
    { "Stromerzeugung-Regenerativ-Sonstige", "https://oklabpdm.uber.space/powergeneration/rest/other_renewables.json", "other_renewables", 5 },

    { "Stromerzeugung-Konventionell-Kernenergie", "https://oklabpdm.uber.space/powergeneration/rest/nuclear.json", "nuclear", 5 },
    { "Stromerzeugung-Konventionell-Kohle", "https://oklabpdm.uber.space/powergeneration/rest/coal.json", "coal", 5 },
    { "Stromerzeugung-Konventionell-Braunkohle", "https://oklabpdm.uber.space/powergeneration/rest/lignite.json", "lignite", 5 },
    { "Stromerzeugung-Konventionell-Steinkohle", "https://oklabpdm.uber.space/powergeneration/rest/hardcoal.json", "hardcoal", 5 },
    { "Stromerzeugung-Konventionell-Erdgas", "https://oklabpdm.uber.space/powergeneration/rest/natural_gas.json", "natural_gas", 5 },
    { "Stromerzeugung-Konventionell-Pumpspeicher", "https://oklabpdm.uber.space/powergeneration/rest/pumped_storage.json", "pumped_storage", 5 },
    { "Stromerzeugung-Konventionell-Sonstige", "https://oklabpdm.uber.space/powergeneration/rest/other_conventional.json", "other_conventional", 5 }
    */
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
