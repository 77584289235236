import { Component, OnInit } from '@angular/core';
import {interval} from 'rxjs';
import { Tile } from '../_models';
import {SimulationService} from '@app/_services';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent implements OnInit {

  public data: any;
  private timer;

  renewables: Tile[] = [
    {
      key:    'total_renewables',
      title:  'Erneuerbare Gesamt',
      icon:   'Erneuerbare-alle.svg',
      color:  '#666',
      value:  0
    },
    {
      key:    'hydropower',
      title:  'Wasserkraft',
      icon:   'Erneuerbare-Wasserkraft.svg',
      color:  '#666',
      value:  0
    },
    {
      key:    'wind',
      title:  'Wind On- & Offshore',
      icon:   'Erneuerbare-Wind.svg',
      color:  '#666',
      value:  0
    },
    {
      key:    'photovoltaics',
      title:  'Photovoltaik',
      icon:   'Erneuerbare-Photovoltaik.svg',
      color:  '#666',
      value:  0
    },
    {
      key:    'other_renewables',
      title:  'Sonstige Erneuerbare',
      icon:   'Erneuerbare-sonstige.svg',
      color:  '#666',
      value:  0
    }
  ];
  conventional: Tile[] = [
    {
      key:    'total_conventional',
      title:  'Konventionelle Gesamt',
      icon:   'Konventionelle-alle.svg',
      color:  '#666',
      value:  0
    },
    {
      key:    'nuclear',
      title:  'Kernenergie',
      icon:   'Konventionelle-Kernkraft.svg',
      color:  '#666',
      value:  0
    },
    {
      key:    'coal',
      title:  'Braun- & Steinkohle',
      icon:   'Konventionelle-Kohle.svg',
      color:  '#666',
      value:  0
    },
    {
      key:    'natural_gas',
      title:  'Erdgas',
      icon:   'Konventionelle-Erdgas.svg',
      color:  '#666',
      value:  0
    },
    {
      key:    'other_conventional',
      title:  'Sonstige Konventionelle',
      icon:   'Konventionelle-sonstige.svg',
      color:  '#666',
      value:  0
    }
  ];

  public tiles: Tile[] = [];
  public currentTime: string = null;

  constructor( private simulationService: SimulationService ) { }

  ngOnInit(): void {

    this.tiles = this.renewables.concat(this.conventional);

    // init timer
    // const source = timer(10, 5000);
    const source = interval(5000);
    this.timer = source.subscribe(val => {

      // this.data = this.simulationService.getAll();
      console.log(val);

      this.simulationService.getAll().subscribe( ( response ) => {

        this.currentTime = response.datetime;

        this.renewables.forEach(function (tile) {
          let color = response.subsets[tile.key].color;
          tile.color = 'rgb(' + color.r + ',' + color.g + ',' + color.b + ')';
          tile.value  = response.subsets[tile.key].value;
          tile.min    = response.subsets[tile.key].min;
          tile.max    = response.subsets[tile.key].max;
          tile.perc   = Math.round(100 / (tile.max - tile.min) * tile.value );
          console.log(tile.value, tile.min, tile.max, tile.perc);
        });

        this.conventional.forEach(function (tile) {
          let color = response.subsets[tile.key].color;
          tile.color = 'rgb(' + color.r + ',' + color.g + ',' + color.b + ')';
          tile.value  = response.subsets[tile.key].value;
          tile.min    = response.subsets[tile.key].min;
          tile.max    = response.subsets[tile.key].max;
          tile.perc   = Math.round(100 / (tile.max - tile.min) * tile.value );
          console.log(tile.key, tile.color);
        });

        this.tiles = this.renewables.concat(this.conventional);
        //return response;
      });

    });
  }

  onTime(): void {
    console.log('TIME!');
  }

  ngOnDestroy() {
    this.timer.unsubscribe();
  }

}
