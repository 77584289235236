import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Simulationdata } from '@app/_models/';

@Injectable({ providedIn: 'root' })
export class SimulationService {

  private data: Simulationdata;
  constructor(private http: HttpClient) { }

  getAll() {
    console.log(`${environment.simulationUrl}/full.json`);
    // return this.http.get<any>(`${environment.simulationUrl}/full.json`);
    // return this.http.get<any>(`https://oklabpdm.uber.space/powergeneration/data/all/00-01-20.json`);


    return this.http.get<any>(`${environment.simulationUrl}/full.json`)
    .pipe(map(result => {

      this.data = {
        timestmp: result.timestmp,
        datetime: result.datetime,
        biomass: result.biomass,
        hydropower: result.hydropower,
        wind: result.wind,
        windOffshore: result.wind_offshore,
        windOnshore: result.wind_onshore,
        photovoltaics: result.photovoltaics,
        otherRenewables: result.other_renewables,
        nuclear: result.nuclear,
        lignite: result.lignite,
        coal: result.coal,
        hardcoal: result.hardcoal,
        naturalGas: result.natural_gas,
        pumpedStorage: result.pumped_storage,
        otherConventional: result.other_conventional,
        totalRenewables: result.total_renewables,
        totalConventional: result.total_conventional,
        totalAll: result.total_all,
        subsets: result.data
      };

      return this.data;
    }));
  }
}
