import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {  SimulationService} from '../_services';
import { interval } from 'rxjs';

import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';

@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss']
})
export class BarchartComponent implements OnInit, OnDestroy {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;


  public data: any;
  private timer;

  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = ['Durchschnitt 2019'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [1246.75], label: 'Biomasse', backgroundColor: '#55B256' }, // biomass
    { data: [660.25], label: 'Wasserkraft', backgroundColor: '#A8E3FE' }, // hydropower
    { data: [1710], label: 'Wind Offshore', backgroundColor: '#1FB9F0' }, // wind_offshore
    { data: [9256], label: 'Wind Onshore', backgroundColor: '#5C6CFB' }, // wind_onshore
    { data: [7554.75], label: 'Photovoltaik', backgroundColor: '#FFF153' }, // photovoltaics
    { data: [50], label: 'Sonst. Erneuerbare', backgroundColor: '#89CC62' }, // other_renewables

    { data: [2360], label: 'Kernenergie', backgroundColor: '#8C4E4E' }, // nuclear
    { data: [4205], label: 'Braunkohle', backgroundColor: '#997B5E' }, // lignite
    { data: [4169], label: 'Steinkohle', backgroundColor: '#414141' }, // hardcoal
    { data: [2748], label: 'Erdgas', backgroundColor: '#C8DADA' }, // natural_gas
    { data: [1931.75], label: 'Pumpspeicher', backgroundColor: '#445573' }, // pumped_storage
    { data: [891], label: 'Sonst. Konvent.', backgroundColor: '#768076' }, // other_conventional
  ];

  constructor( private simulationService: SimulationService ) { }

  ngOnInit(): void {

    // init timer
    // const source = timer(10, 5000);
    const source = interval(5000);
    this.timer = source.subscribe(val => {

      // this.data = this.simulationService.getAll();
      console.log(val);

      this.simulationService.getAll().subscribe( ( response ) => {

        this.barChartData[0].data[0] = response.biomass;
        this.barChartData[1].data[0] = response.hydropower;
        this.barChartData[2].data[0] = response.windOffshore;
        this.barChartData[3].data[0] = response.windOnshore;
        this.barChartData[4].data[0] = response.photovoltaics;
        this.barChartData[5].data[0] = response.otherRenewables;

        this.barChartData[6].data[0] = response.nuclear;
        this.barChartData[7].data[0] = response.lignite;
        this.barChartData[8].data[0] = response.hardcoal;
        this.barChartData[9].data[0] = response.naturalGas;
        this.barChartData[10].data[0] = response.pumpedStorage;
        this.barChartData[11].data[0] = response.otherConventional;

        this.barChartLabels[0] = response.datetime;

        // this.chart.chart.update();
        this.updateChart();

        return response;
      });

    });
  }

  onTime(): void {
    console.log('TIME!');
  }

  updateChart() {
    this.chart.chart.update(); // This re-renders the canvas element.
  }

  ngOnDestroy() {
    this.timer.unsubscribe();
    //this.chart.chart.destroy();
  }

}
